<template>
  <div class="overflow-hidden height-full shopping-cart-list-table flex flex-column">
    <div v-if="isKind" class="text-price p-t-10 p-b-10 p-l-10">
      <span>已添加商品种类</span>
      <span> {{ goodsInfo.totalCategories }} </span>
      <span> ｜ </span>
      <span>商品件数</span>
      <span> {{ goodsInfo.totalQty }} </span>
      <span> ｜ </span>
      <span>促销</span>
      <span> {{ goodsInfo.promotionQty }} </span>
      <span> ｜ </span>
      <span>降价</span>
      <span> {{ goodsInfo.reducePriceQty }} </span>
    </div>
    <div class="shopping-cart-list-table-form flex flex-row-center">
      <span v-if="isName" class="m-r-10">购物车</span>
      <el-input
        ref="SearchInput"
        v-model="keyword"
        clearable
        size="mini"
        placeholder="商品名称/编码/规格/车型/生产码等关键字"
        style="width: 300px"
        :disabled="noLoginLook"
        @keyup.enter.native="query"
      ></el-input>
      <el-button type="primary" size="mini" class="m-l-20" @click="query" :disabled="noLoginLook">查询(F5)</el-button>
      <el-button size="mini" class="m-l-20" @click="reset" :disabled="noLoginLook">重置(F6)</el-button>
      <div class="flex flex-center m-l-20">
        <span>促销：</span>
        <ht-select
          v-model="queryData.isPromotion"
          :options="promotionOptions"
          :clearable="false"
          size="mini"
          placeholder="促销"
          class="w-140"
          :disabled="noLoginLook"
          @change="query"
        />
      </div>
      <el-checkbox v-model="queryData.isReducePrice" class="m-l-20" @change="query" :disabled="noLoginLook">
        降价商品(Alt+A)
      </el-checkbox>
      <el-checkbox v-model="queryData.isNormalBuy" @change="query" :disabled="noLoginLook">常购商品(Alt+B)</el-checkbox>
      <div class="flex1"></div>
    </div>
    <div class="flex1 overflow-hidden p-a-5">
      <HtTable
        ref="shoppingCartListTable"
        v-bind="$attrs"
        v-on="$listeners"
        :loading="loading"
        :data="partsListData"
        :columns="shoppingCartListTableColumns"
        :selected-rows.sync="selectedRows"
        :location-row-index="locationRowIndex"
        :table-name="shoppingCartListTable.tableName"
        :show-table-setting="true"
        :is-show-default-select-column="true"
        :is-row-click-select="false"
        :is-key-down-current-row-up-select="false"
        :is-key-down-current-row-down-select="false"
        :pagination="false"
        :key-scope="keyScope"
        selection-type="multiple"
        @table-setting="editColumnsVisible = true"
        @hotkeysUp="hotkeysUp"
        @allSelected="allSelected"
        @checkbox-change="checkboxChange"
        @all-selection-click="allSelectionClick"
        @set-location-row-index="setLocationRowIndex"
      />
    </div>
    <div class="shopping-cart-footer flex flex-row-center">
      <el-checkbox
        v-model="isAllSelected"
        :disabled="!partsListData.length"
        @change="changeIsAllSelected"
        @click.native="allSelectedClick"
      >
        全选
      </el-checkbox>
      <span class="m-l-20">批量操作</span>
      <el-button
        v-loading="loading"
        class="m-l-20"
        type="danger"
        size="mini"
        :disabled="!selectedRows.length"
        @click="deleteBatchData"
      >
        删除(F3)
      </el-button>
      <el-button
        v-loading="loading"
        class="m-l-10"
        type="primary"
        size="mini"
        :disabled="!partsListData.length"
        @click="deleteAllData"
      >
        清空购物车(F11)
      </el-button>
      <div class="flex1"></div>
      <div class="flex flex-column">
        <div class="text-right">
          <span class="font-size-16">已选{{ selectedNum }}种，{{ selectedGoodsNum }}件商品</span>
          <span class="m-l-10 font-size-16 text-red font-weight-bolder">
            合计：{{ isNaN(selectedGoodsSumPrice) ? "议价" : `￥${selectedGoodsSumPrice}` }}
          </span>
        </div>
        <div v-if="discountedMoney > 0" class="text-right">
          <span>
            原价：
            <span class="text-decoration-l-t">¥{{ originalPrice }}</span>
          </span>
          <span class="m-l-10 text-red">已优惠：¥{{ discountedMoney }}</span>
        </div>
      </div>
      <el-button
        class="m-l-20 font-size-18"
        type="warning"
        size="medium"
        round
        :disabled="!selectedRows.length"
        @click="placeOrder"
      >
        去下单(F7)
      </el-button>
    </div>
    <EditColumns
      :table-name="shoppingCartListTable.tableName"
      :base-columns="shoppingCartListTableBaseColumns"
      :visible.sync="editColumnsVisible"
      @columns-change="columnsChange"
    />
    <PlaceOrderType ref="placeOrderType" />
    <!-- 展示图片框 -->
    <div class="shopping-image__preview" style="display:none;">
      <el-image ref="imgpreview" style="width: 0px; height: 0px" :src="imageSrc" :preview-src-list="imagesList">
      </el-image>
    </div>
    <ProductsDetailsBox :isShoppingCart="true" ref="productsDetailsBox" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProductsDetailsBox from "@/components/ProductsDetailsBox";
import { SHOPPING_CART_LIST_TABLE } from "@/constants/shopping-cart-list-table";
import PlaceOrderType from "@/components/PlaceOrderType";
import { getShoppingCartList, editShoppingCart, deleteShoppingCart, shoppingCartChecked } from "@/api/shoppingCart";

export default {
  name: "ShoppingCartListTable",
  components: {
    PlaceOrderType,
    ProductsDetailsBox
  },
  props: {
    isKind: {
      type: Boolean,
      default: false
    },
    isName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      imageSrc: "",
      imagesList: [],
      loading: false,
      keyScope: "shoppingCartListTable",
      locationRowIndex: 0,
      selectedRows: [],
      editColumnsVisible: false,
      shoppingCartListTable: SHOPPING_CART_LIST_TABLE,
      shoppingCartListTableColumns: [],
      partsListData: [],
      isAllSelected: false,
      keyword: "",
      queryData: {
        keyword: "", // 关键字
        isNormalBuy: false, // 是否常购配件，true是
        isPromotion: 0, // 促销: 0全部/1是/2否
        isReducePrice: false // 是否降价商品，true是
      },
      promotionOptions: [
        {
          id: 0,
          name: "全部"
        },
        {
          id: 1,
          name: "是"
        },
        {
          id: 2,
          name: "否"
        }
      ],
      goodsInfo: {}
    };
  },
  computed: {
    ...mapState(["table"]),
    ...mapGetters("companyInfo", ["noLoginLook"]),
    company() {
      return this.$store.state.companyInfo.company;
    },
    shoppingCartListTableBaseColumns() {
      const columns = JSON.parse(JSON.stringify(this.shoppingCartListTable.columns));
      //库存展示维度是否勾选仓库
      if (!this.company.setting.stockShowModeWarehouse) {
        const warehouseIndex = columns.findIndex(item => item.prop === "warehouse");
        columns.splice(warehouseIndex, 1);
      }
      //库存展示维度是否勾选生产批号
      if (!this.company.setting.stockShowModeProductBatch) {
        const productBatchIndex = columns.findIndex(item => item.prop === "productBatch");
        columns.splice(productBatchIndex, 1);
      }
      return columns;
    },
    selectedNum() {
      return this.selectedRows.length;
    },
    selectedGoodsNum() {
      let num = 0;
      this.selectedRows.map(item => {
        num += Number(item.qty);
      });
      return Number(num.toFixed(2));
    },
    selectedGoodsSumPrice() {
      let sum = 0;
      this.selectedRows.map(item => {
        sum += Number(item.qty) * Number(item.goods.price);
      });
      return Number(sum.toFixed(2));
    },
    originalPrice() {
      let sum = 0;
      this.selectedRows.map(item => {
        sum += Number(item.qty) * Number(item.goods.originalPrice);
      });
      return Number(sum.toFixed(2));
    },
    discountedMoney() {
      return isNaN(Number(this.selectedGoodsSumPrice))
        ? "议价"
        : Number((this.originalPrice - this.selectedGoodsSumPrice).toFixed(2));
    }
  },
  mounted() {
    this.$bus.$on("shoppingCartRefresh", () => {
      this.getShoppingCartList();
    });
    if (localStorage.isLogin === "true") {
      this.getShoppingCartList();
    }
  },
  activated() {
    if (!this.loading && localStorage.isLogin === "true") {
      this.getShoppingCartList();
    }
  },
  methods: {
    reset() {
      this.queryData = {
        keyword: "", // 关键字
        isNormalBuy: false, // 是否常购配件，true是
        isPromotion: 0, // 促销: 0全部/1是/2否
        isReducePrice: false // 是否降价商品，true是
      };
      this.keyword = "";
      this.query();
    },
    getShoppingCartList() {
      if (this.noLoginLook) {
        this.$router.push({
          path: "/login"
        });
        return;
      }
      const data = this.dealWithQueryData(this.queryData);
      this.loading = true;
      getShoppingCartList(data)
        .then(res => {
          this.loading = false;
          if (res) {
            this.dealWithTableData(res);
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.code !== "force-reset-password") {
            this.$message.error(err.message || "购物车加载失败");
          }
        });
    },
    dealWithQueryData(data) {
      data = window.tool.deepClone(data);
      return data;
    },
    dealWithTableData(res) {
      this.goodsInfo = res;
      let data = res.rows || [];
      // 数据处理
      data = data.map(item => {
        item.oldQty = Number(item.qty);
        // 单行小计
        item.total = isNaN(Number(item.qty * item.goods.price))
          ? "议价"
          : Number((item.qty * item.goods.price).toFixed(2));
        // 比加入时
        item.priceGap = Number(item.priceGap);
        if (item.priceGap > 0) {
          item.priceGapName = `比加入时涨价${item.priceGap}元`;
        }
        if (item.priceGap < 0) {
          item.priceGapName = `比加入时降价${Math.abs(item.priceGap)}元`;
        }
        if (Number(item.goods.minBuyQty) && Number(item.goods.uQty)) {
          item.goods.minQty = window.tool.getLCM(Number(item.goods.minBuyQty), Number(item.goods.uQty));
        } else {
          item.goods.minQty = 1;
        }
        // 无货
        item.outOfStock = String(item.goods.qty).includes("无货");
        // 已失效
        item.lostEffectiveness = Number(item.goods.id) < 1;
        // 是否选中
        item.selection = item.checked;
        return item;
      });
      this.partsListData = data;
      this.$store.dispatch("user/setShoppingCartNum", res.totalQty || 0);
    },
    columnsChange(columns) {
      this.shoppingCartListTableColumns = [];
      this.$nextTick(() => {
        this.shoppingCartListTableColumns = columns.map(item => {
          if (item.prop === "control") {
            return {
              ...item,
              render: (value, row) => {
                return (
                  <div on-click={e => this.deleteData(row, item, e)} class="flex flex-center">
                    <el-tooltip effect="dark" content="删除" placement="top">
                      <i class="el-icon-remove text-danger font-size-18 cursor-pointer" />
                    </el-tooltip>
                  </div>
                );
              }
            };
          } else if (item.prop === "qty") {
            return {
              ...item,
              render: (value, row) => {
                return (
                  <div on-click={e => e.stopPropagation()}>
                    {Number(row.goods.minBuyQty) > 1 ? <div class="text-red">起订量：{row.goods.minBuyQty}</div> : null}
                    <div>
                      <el-input-number
                        style="max-width:110px"
                        v-model={row.qty}
                        min={Number(row.goods.minQty)}
                        max={9999999}
                        step={Number(row.goods.uQty)}
                        size="mini"
                        disabled={row.outOfStock || row.lostEffectiveness}
                        on-change={e => this.qtyChange(e, row)}
                      />
                      <span class="m-l-5">{row.goods.unit}</span>
                    </div>
                    {/*<div class="text-red">今日限购，当前可订货1</div>*/}
                  </div>
                );
              }
            };
          } else if (item.prop === "createdAt") {
            return {
              ...item,
              render: (value, row) => {
                return <span>{this.$dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>;
              }
            };
          } else if (item.prop.includes("name")) {
            return {
              ...item,
              render: (value, row) => {
                return (
                  <div>
                    {/*<el-tag type="warning" size="mini" class="m-r-5">
                      热销
                    </el-tag>
                    <el-tag size="mini" class="m-r-5">
                      新品
                    </el-tag>
                    <el-tag type="warning" size="mini" class="m-r-5">
                      特价
                    </el-tag>*/}
                    <div class="text-primary">
                      <el-tooltip effect="dark" content={value} placement="top">
                        <span class="cursor-pointer" on-click={e => this.productsDetails(row, e)}>
                          {value}
                        </span>
                      </el-tooltip>
                      <span class="cursor-pointer" on-click={e => this.showImg(row.goods, e)}>
                        {row.goods.images && row.goods.images.length ? <span class="tag-picture">图</span> : null}
                      </span>
                      <span class="cursor-pointer" on-click={e => this.productsDetails(row, e)}>
                        {row.goods.extaCode ? <span class="tag-exchange">互</span> : null}
                      </span>
                    </div>
                    <div>
                      <span class="cursor-pointer" on-click={e => this.productsDetails(row, e)}>
                        {row.goods.promotion ? <span class="text-red">{row.goods.promotion}</span> : null}
                      </span>
                    </div>
                    <div>
                      {row.outOfStock ? <span class="text-red">无货</span> : null}
                      {row.lostEffectiveness ? <span class="text-red">已失效</span> : null}
                    </div>
                  </div>
                );
              }
            };
          } else if (item.prop === "total") {
            return {
              ...item,
              render: value => {
                return <div>{isNaN(Number(value)) ? value : `￥${value}`}</div>;
              }
            };
          } else if (item.prop.includes("price")) {
            return {
              ...item,
              render: (value, row) => {
                return (
                  <div>
                    <div>{value}</div>
                    {Number(row.goods.linePrice) > 0 ? <div class="line-price">￥{row.goods.linePrice}</div> : null}
                    <div class="text-red">{row.priceGapName}</div>
                    {Number(row.goods.lastPrice) > 0 ? (
                      <div class="text-red">上次售价：{row.goods.lastPrice}</div>
                    ) : null}
                  </div>
                );
              }
            };
          } else if (item.prop === "buyParams") {
            return {
              ...item,
              render: (value, row) => {
                return row.goodsSpecification?.show ? (
                  <div class="buy-params position-relative" on-click={e => this.editDetails(row, e)}>
                    <span class="buy-params-edit">修改</span>
                    {this.isSpecificationExpired(row) ? (
                      <span class="text-red">购买规格已失效，请重新选择</span>
                    ) : (
                      <span>{row.goodsSpecification.show}</span>
                    )}
                  </div>
                ) : null;
              }
            };
          }
          return item;
        });
      });
    },

    // 是否规格已失效
    isSpecificationExpired(row) {
      return row.goodsSpecification.show.includes("已失效");
    },
    //弹出多规格购买弹框
    editDetails(row, e) {
      e && e.stopPropagation();
      let goodsItem = Object.assign(row.goods, {
        goodsNum: row.qty,
        carts_id: row.id,
        goodsSpecification: row.goodsSpecification
      });
      this.$refs.productsDetailsBox.show(goodsItem);
    },
    showImg(row, e) {
      e.stopPropagation();
      this.imageSrc = row.images[0];
      this.imagesList = row.images;
      setTimeout(() => {
        document.querySelector(".shopping-image__preview .el-image__preview").click();
      }, 200);
    },
    query() {
      this.queryData.keyword = this.keyword;
      this.getShoppingCartList();
      // 查询后默认聚焦到表格中
      this.$refs.SearchInput.blur();
      this.$refs.shoppingCartListTable.$el.click();
    },
    hotkeysUp(e) {
      switch (e.code) {
        case "F3":
          e.preventDefault();
          e.stopPropagation();
          if (this.selectedRows.length) {
            this.deleteBatchData();
          }
          break;
        case "F5":
          e.preventDefault();
          e.stopPropagation();
          this.query();
          break;
        case "F6":
          e.preventDefault();
          e.stopPropagation();
          this.reset();
          break;
        case "F7":
          e.preventDefault();
          e.stopPropagation();
          if (this.selectedRows.length) {
            this.placeOrder();
          }
          break;
        case "F11":
          e.preventDefault();
          e.stopPropagation();
          if (this.partsListData.length) {
            this.deleteAllData();
          }
          break;
        case "KeyA":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.queryData.isReducePrice = !this.queryData.isReducePrice;
            this.query();
          }
          break;
        case "KeyB":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.queryData.isNormalBuy = !this.queryData.isNormalBuy;
            this.query();
          }
          break;
      }
    },
    qtyChange(e, row) {
      if (row.outOfStock) {
        this.$message.warning("抱歉，商品无货！");
        return;
      }
      if (row.lostEffectiveness) {
        this.$message.warning("抱歉，商品已失效！");
        return;
      }
      const data = {
        qty: e,
        id: row.id
      };
      editShoppingCart(data)
        .then(() => {
          this.$message.success("修改成功");
          getShoppingCartList(this.dealWithQueryData(this.queryData)).then(res => {
            this.dealWithTableData(res);
          });
          this.$emit("refreshList");
        })
        .catch(err => {
          this.$message.error(err.message || "修改失败");
          getShoppingCartList(this.dealWithQueryData(this.queryData)).then(res => {
            this.dealWithTableData(res);
          });
        });
    },
    deleteData(row, columns, e) {
      e.stopPropagation();
      this.$refs.shoppingCartListTable.rowClick(row, columns, e, true);

      setTimeout(() => {
        const data = {
          ids: [row.id]
        };
        this.deleteShoppingCart(data);
      }, 300);
    },
    deleteBatchData() {
      const ids = this.selectedRows.map(item => item.id);
      const data = {
        ids: ids
      };
      this.deleteShoppingCart(data);
    },
    deleteAllData() {
      const data = {
        all: true
      };
      this.deleteShoppingCart(data);
    },
    deleteShoppingCart(data) {
      this.loading = true;
      deleteShoppingCart(data)
        .then(() => {
          this.loading = false;
          this.$message.success("删除成功");
          this.query();
          this.$emit("refreshList");
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "删除失败");
        });
    },
    changeIsAllSelected() {
      this.$refs.shoppingCartListTable.handleAllSelectedChange(this.isAllSelected);
    },
    // 是否全选
    allSelected(e) {
      this.isAllSelected = e;
    },
    // 全选按钮点击事件
    allSelectedClick(e) {
      if (e.target.tagName === "INPUT") return;
      setTimeout(() => {
        if (this.partsListData.length) {
          this.shoppingCartChecked([], this.isAllSelected, true);
        }
      }, 0);
    },
    // 表格表头点击事件
    allSelectionClick(e) {
      if (this.partsListData.length) {
        this.shoppingCartChecked([], e, true);
      }
    },
    // 行选择事件
    checkboxChange(e, row) {
      this.shoppingCartChecked([row.id], row.selection);
    },
    shoppingCartChecked(ids, checked, all = false) {
      const data = {
        ids: ids,
        all: all,
        checked: checked
      };
      shoppingCartChecked(data);
    },
    setLocationRowIndex(index) {
      this.locationRowIndex = index;
    },
    productsDetails(row, e) {
      e.stopPropagation();
      if (row.lostEffectiveness) {
        this.$message.warning("抱歉，商品已失效！");
        return;
      }
      this.$router.push({
        path: "/products/details",
        query: {
          data: encodeURIComponent(JSON.stringify(row.goods))
        }
      });
    },
    placeOrder() {
      // 过滤出无货和已失效商品
      const selectedRows = this.selectedRows.filter(item => !item.outOfStock && !item.lostEffectiveness);
      if (!selectedRows.length) {
        this.$message.warning("请勾选要下单的商品！");
        return;
      }
      this.$refs.placeOrderType.show({
        type: "shoppingCart",
        data: window.tool.deepClone(selectedRows)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.shopping-cart-list-table {
  .shopping-cart-list-table-form {
    padding: 4px;
    box-sizing: border-box;
    background-color: #f2f2f2;
  }
  .tag-picture,
  .tag-exchange {
    width: 20px;
    height: 20px;
    color: white;
    font-size: 12px;
    margin-left: 10px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .tag-picture {
    background-color: #298fd9;
  }
  .tag-exchange {
    background-color: #f28841;
  }
  .shopping-cart-footer {
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
  }
  ::v-deep .el-input-number--mini .el-input__inner {
    padding-left: 40px;
    padding-right: 40px;
  }
  .buy-params {
    cursor: pointer;
    // text-align: center;
    white-space: normal;
    padding: 15px 5px;
    margin: 5px;
    .buy-params-edit {
      display: none;
    }
    &:hover {
      border: 1px dashed red;
      border-radius: 4px;
      .buy-params-edit {
        display: block;
        background: red;
        color: #ffffff;
        position: absolute;
        font-size: 12px;
        transform: scale(0.8);
        top: 4px;
        right: 7px;
        padding: 0 4px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
}
</style>
